import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
  <svg
    className={css.iconSvg}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.87578 1.48975C3.90386 1.48975 1.49464 3.89897 1.49464 6.8709C1.49464 9.84282 3.90386 12.252 6.87578 12.252C8.36191 12.252 9.70637 11.6504 10.6808 10.6759C11.6553 9.70149 12.2569 8.35702 12.2569 6.8709C12.2569 3.89897 9.8477 1.48975 6.87578 1.48975ZM0.298828 6.8709C0.298828 3.23855 3.24343 0.293945 6.87578 0.293945C10.5081 0.293945 13.4527 3.23855 13.4527 6.8709C13.4527 8.47155 12.8803 9.93939 11.93 11.0796L15.6692 14.8188C15.9027 15.0523 15.9027 15.4308 15.6692 15.6643C15.4357 15.8978 15.0572 15.8978 14.8237 15.6643L11.0844 11.9251C9.94427 12.8754 8.47643 13.4478 6.87578 13.4478C3.24343 13.4478 0.298828 10.5032 0.298828 6.8709Z"
      fill="#89898A"
    />
  </svg>
);

export default IconSearchDesktop;
