import React, { useState, useRef, useEffect } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { injectIntl, intlShape } from '../../../util/reactIntl';

import { FieldTextInput } from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './KeywordFilter.module.css';
import TopbarSearchForm from '../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { isOriginInUse } from '../../../util/search';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { parse } from '../../../util/urlHelpers';

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;
// Short search queries (e.g. 2 letters) have a longer timeout before search is made
const TIMEOUT_FOR_SHORT_QUERIES = 2000;

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'keywords';
};

const KeywordFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    initialValues,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    intl,
    showAsPopup,
    config,
    isDesktop,
    ...rest
  } = props;

  const [shortKeywordTimeout, setShortKeywordTimeout] = useState(null);
  const mobileInputRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  const mapSearch = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  useEffect(() => {
    return () => {
      window.clearTimeout(shortKeywordTimeout);
    };
  }, [shortKeywordTimeout]);

  const classes = classNames(rootClassName || css.root, className);

  const urlParam = getKeywordQueryParam(queryParamNames);
  const hasInitialValues = mapSearch?.address;

  const labelForPopup = hasInitialValues
    ? intl.formatMessage({ id: 'KeywordFilter.labelSelected' }, { labelText: mapSearch?.address })
    : label;

  const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;

  const labelForPlain = <span className={labelClass}>{label}</span>;

  const filterText = intl.formatMessage({ id: 'KeywordFilter.filterText' });
  const placeholder = intl.formatMessage({ id: 'KeywordFilter.placeholder' });

  // pass the initial values with the name key so that
  // they can be passed to the correct field
  const namedInitialValues = { [name]: initialValues[urlParam] };

  const handleSubmit = values => {
    const usedValue = values ? values[name] : values;
    onSubmit({ [urlParam]: usedValue });
  };

  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  // Use timeout for short queries and debounce for queries with any length
  const handleChangeWithDebounce = values => {
    const hasKeywordValue = values && values[name];
    const keywordValue = hasKeywordValue ? values[name] : '';
    if (!hasKeywordValue || (hasKeywordValue && keywordValue.length >= 3)) {
      if (shortKeywordTimeout) {
        window.clearTimeout(shortKeywordTimeout);
      }
      return debouncedSubmit(values);
    } else {
      const timeoutId = window.setTimeout(() => {
        if (mobileInputRef && mobileInputRef.current) {
          handleSubmit({ ...values, [name]: mobileInputRef.current.value });
        } else {
          handleSubmit(values);
        }
      }, TIMEOUT_FOR_SHORT_QUERIES);
      setShortKeywordTimeout(timeoutId);
    }
  };

  const handleClear = () => {
    if (mobileInputRef && mobileInputRef.current) {
      mobileInputRef.current.value = '';
    }

    const { address, bounds, ...rest } = mapSearch || {};

    const newUrl = createResourceLocatorString('SearchPage', routeConfiguration(), {}, { ...rest });

    // Construct the full URL with the new query params
    const updatedUrl = `${newUrl}`;
    if (typeof window !== 'undefined') {
      // Use window to change the URL
      window.location.href = updatedUrl;
    }
  };

  const handleLocationSubmit = values => {
    const { location = null } = values || {};
    const { search, selectedPlace } = location || {};
    const { origin, bounds } = selectedPlace || {};
    const originMaybe = isOriginInUse(config) ? { origin } : {};

    const searchQuery = {
      ...mapSearch,
      ...originMaybe,
      address: search,
      bounds,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchQuery));
  };

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      name={name}
      label={labelForPopup}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      labelMaxWidth={250}
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={namedInitialValues}
      keepDirtyOnReinitialize
      isDesktop={isDesktop}
      onClear={() => {
        const { address, bounds, ...rest } = mapSearch || {};

        const newUrl = createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { ...rest }
        );

        // Construct the full URL with the new query params
        const updatedUrl = `${newUrl}`;
        if (typeof window !== 'undefined') {
          // Use window to change the URL
          window.location.href = updatedUrl;
        }
      }}
      {...rest}
    >
      <TopbarSearchForm
        onSubmit={handleLocationSubmit}
        initialValues={{ location: mapSearch }}
        isMobile
        appConfig={config}
        labelForPopup={labelForPopup}
        isDesktop={isDesktop}
      />
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={labelForPlain}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleChangeWithDebounce}
      onClear={handleClear}
      initialValues={namedInitialValues}
      {...rest}
    >
      <fieldset className={css.fieldPlain}>
        <label className={css.fieldPlainLabel} htmlFor={`${id}-input`}>
          {filterText}
        </label>
        <TopbarSearchForm
          onSubmit={handleLocationSubmit}
          initialValues={{ location: mapSearch }}
          isMobile
          labelForPopup={labelForPopup}
          appConfig={config}
        />
      </fieldset>
    </FilterPlain>
  );
};

KeywordFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

KeywordFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(KeywordFilter);
